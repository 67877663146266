/* eslint-disable */
/* tslint:disable */
export function LocaleKeys() {
  return {
    Pages_Panel_ShopPage_Settings_Delete: 'Pages_Panel_ShopPage_Settings_Delete', /* Delete */
    Pages_Panel_addPageCTA: 'Pages_Panel_addPageCTA', /* Add a New Page */
    Pages_Panel_manageCTA: 'Pages_Panel_manageCTA', /* Manage Store */
    Pages_Panel_storesPages: 'Pages_Panel_storesPages', /* Store Pages */
    Pages_Panel_pageInfo: 'Pages_Panel_pageInfo', /* Page Info */
    Pages_Store_Cart_Info_WhatisTitle: 'Pages_Store_Cart_Info_WhatisTitle', /* What is a Cart page? */
    Pages_Store_Cart_Info_WhatisText: 'Pages_Store_Cart_Info_WhatisText', /* This is your cart page. Customers can see the products they've added to their cart, edit quantities and checkout from this page. */
    Pages_Store_Cart_Info_HowTitle: 'Pages_Store_Cart_Info_HowTitle', /* How does it behave? */
    Pages_Store_Cart_Info_HowText: 'Pages_Store_Cart_Info_HowText', /* This cart icon opens up a quick view of the shopping cart. It will be updated every time an item is added. */
    Pages_Store_Product_Info_WhatisTitle: 'Pages_Store_Product_Info_WhatisTitle', /* What is a product page? */
    Pages_Store_Product_Info_WhatisText: 'Pages_Store_Product_Info_WhatisText', /* Your buyers will see this page when they click on a product in your store. */
    Pages_Store_Product_Info_HowTitle: 'Pages_Store_Product_Info_HowTitle', /* How does it behave? */
    Pages_Store_Product_Info_HowText: 'Pages_Store_Product_Info_HowText', /* This is a template page. Any change you make will affect all of your product pages. Each product will have its own page. */
    Pages_Store_Product_Info_ChangeTitle: 'Pages_Store_Product_Info_ChangeTitle', /* How can I change its content? */
    Pages_Store_Product_Info_ChangeText: 'Pages_Store_Product_Info_ChangeText', /* To create your own products and add info, images and more, go to the {{Pages_Store_Product_Info_ChangeLink}}. */
    Pages_Store_Thankyou_Info_HowTitle: 'Pages_Store_Thankyou_Info_HowTitle', /* How does it behave? */
    Pages_Store_Thankyou_Info_HowText: 'Pages_Store_Thankyou_Info_HowText', /* This page is displayed only after checkout and is not visible on your site menu. */
    Pages_Store_Thankyou_Info_WhatisTitle: 'Pages_Store_Thankyou_Info_WhatisTitle', /* What is a Thank you page? */
    Pages_Store_Thankyou_Info_WhatisText: 'Pages_Store_Thankyou_Info_WhatisText', /* This is your thank you page. Your customers will see a personalized thank you message and their shipping details after they complete the checkout process. */
    Pages_Panel_SEO: 'Pages_Panel_SEO', /* SEO Basics */
    Pages_Store_Product_Info_ChangeLink: 'Pages_Store_Product_Info_ChangeLink', /* Store Manager */
    settings: {
      productPage: {
        floatingNotification: {
          membersAreaAdded: 'settings.productPage.floatingNotification.membersAreaAdded', /* You’ve added a Members Area to your site with a new Wishlist page. */
          wishlistAdded: 'settings.productPage.floatingNotification.wishlistAdded', /* A new Wishlist page was added to your Members Area. */
          previewWishlist: 'settings.productPage.floatingNotification.previewWishlist', /* Customize Page */
        },
        loadingModal: {
          addingWishlist: {
            $value: 'settings.productPage.loadingModal.addingWishlist', /* Adding Wishlist... */
            firstSubtitle: 'settings.productPage.loadingModal.addingWishlist.firstSubtitle', /* Installing Wishlist settings */
            secondSubtitle: 'settings.productPage.loadingModal.addingWishlist.secondSubtitle', /* Creating Wishlist for your members */
            thirdSubtitle: 'settings.productPage.loadingModal.addingWishlist.thirdSubtitle', /* Done! */
          },
          addingMembersArea: {
            $value: 'settings.productPage.loadingModal.addingMembersArea', /* Adding Your Members Area…  */
            firstSubtitle: 'settings.productPage.loadingModal.addingMembersArea.firstSubtitle', /* Installing Member Area pages */
            secondSubtitle: 'settings.productPage.loadingModal.addingMembersArea.secondSubtitle', /* Creating Wishlist for your members */
            thirdSubtitle: 'settings.productPage.loadingModal.addingMembersArea.thirdSubtitle', /* Done! */
          },
        },
      },
      shopPage: {
        common: {
          panelHeader: 'settings.shopPage.common.panelHeader', /* Shop Page */
        },
        gfpp: {
          mainAction: {
            manageProducts: 'settings.shopPage.gfpp.mainAction.manageProducts', /* Manage Products */
            chooseCollection: 'settings.shopPage.gfpp.mainAction.chooseCollection', /* Choose Category */
          },
        },
      },
      gridGallery: {
        common: {
          panelHeader: 'settings.gridGallery.common.panelHeader', /* Grid Gallery */
        },
      },
    },
    App_Manager_Stores_First_General_Action: 'App_Manager_Stores_First_General_Action', /* Learn More */
    App_Manager_Stores_Third_Quick_Action: 'App_Manager_Stores_Third_Quick_Action', /* Find Products to Sell */
    App_Manager_Stores_First_Quick_Action: 'App_Manager_Stores_First_Quick_Action', /* Add Store Elements */
    App_Manager_Stores_Second_Quick_Action: 'App_Manager_Stores_Second_Quick_Action', /* Manage Pages */
    App_Manager_Stores_Second_General_Action: 'App_Manager_Stores_Second_General_Action', /* Remove App */
    App_Manager_Stores_Main_CTA: 'App_Manager_Stores_Main_CTA', /* Manage Products */
    App_Manager_Stores_Secondary_CTA: 'App_Manager_Stores_Secondary_CTA', /* Go to Dashboard */
    App_Manager_Stores_Fourth_Quick_Action: 'App_Manager_Stores_Fourth_Quick_Action', /* Add Related Apps */
    App_Manager_Stores_Upgrade_Text: 'App_Manager_Stores_Upgrade_Text', /* To accept payments and orders,  */
    App_Manager_Stores_Upgrade_Link: 'App_Manager_Stores_Upgrade_Link', /* upgrade now. */
    NewPages_Panel_StorePages_Panel_AddCollection: 'NewPages_Panel_StorePages_Panel_AddCollection', /* Add a Category Page */
    memberPages: {
      addModal: {
        memberPagesTab: {
          installation: {
            popup: {
              loader: {
                textOne: 'memberPages.addModal.memberPagesTab.installation.popup.loader.textOne', /* Preparing your layout... */
                textTwo: 'memberPages.addModal.memberPagesTab.installation.popup.loader.textTwo', /* Configuring your components... */
                textThree: 'memberPages.addModal.memberPagesTab.installation.popup.loader.textThree', /* Optimizing your pages... */
                textFour: 'memberPages.addModal.memberPagesTab.installation.popup.loader.textFour', /* Adding the finishing touches... */
                textFive: 'memberPages.addModal.memberPagesTab.installation.popup.loader.textFive', /* You’re good to go */
              },
              title: 'memberPages.addModal.memberPagesTab.installation.popup.title', /* Adding Wix Stores */
            },
          },
        },
      },
    },
    NewPages_Panel_StorePages_Panel_AddShopPage_General: 'NewPages_Panel_StorePages_Panel_AddShopPage_General', /* Add Shop Page */
    Pages_Panel_ecomPlatformPages: 'Pages_Panel_ecomPlatformPages', /* Cart & Thank You */
    Installation_Popup_Loader_shop_page_Header: 'Installation_Popup_Loader_shop_page_Header', /* Adding a Shop Page */
    Installation_Popup_Loader_shop_page_Text1: 'Installation_Popup_Loader_shop_page_Text1', /* Preparing your layout... */
    Installation_Popup_Loader_shop_page_Text2: 'Installation_Popup_Loader_shop_page_Text2', /* You’re good to go */
    PagesPanel_StorePages_AddShopPage_MainButton_Tooltip: 'PagesPanel_StorePages_AddShopPage_MainButton_Tooltip', /* Add a new shop page to display products from a specific category. */
    categoryPage: {
      gfpp: {
        manageCategories: 'categoryPage.gfpp.manageCategories', /* Manage Categories */
      },
      optInFlow: {
        step2: {
          modal: {
            switch: {
              button: 'categoryPage.optInFlow.step2.modal.switch.button', /* I Understand, Install Now */
            },
            goBack: {
              button: 'categoryPage.optInFlow.step2.modal.goBack.button', /* Cancel */
            },
            title: 'categoryPage.optInFlow.step2.modal.title', /* This migration will required manual SEO changes */
            body: 'categoryPage.optInFlow.step2.modal.body', /* Having more than one page for each category can harm your site's SEO.

            Once you install the Category Page, you'll need to fix your SEO by manually redirecting your old Shop Page URLs to the new category URLs. */
            footer: {
              text: 'categoryPage.optInFlow.step2.modal.footer.text', /* To learn more about the migration steps, read our */
              learnMoreLink: 'categoryPage.optInFlow.step2.modal.footer.learnMoreLink', /* step-by-step guide. */
            },
          },
        },
        step1: {
          modal: {
            bullet1: 'categoryPage.optInFlow.step1.modal.bullet1', /* Replace your Shop Pages with a single template page */
            learnMoreURL: 'categoryPage.optInFlow.step1.modal.learnMoreURL', /* https://support.wix.com/en/article/about-switching-to-the-new-category-page-experience */
            footer: {
              text: 'categoryPage.optInFlow.step1.modal.footer.text', /* Important: You won’t be able to go back. The migration requires making manual changes to your site, so read the link above to understand the process before deciding if migrating is right for you. */
            },
            title: 'categoryPage.optInFlow.step1.modal.title', /* The new Category Page experience */
            bullet2: 'categoryPage.optInFlow.step1.modal.bullet2', /* Boost your SEO with new settings, images and descriptions */
            switch: {
              button: 'categoryPage.optInFlow.step1.modal.switch.button', /* Migrate to Category Page */
            },
            learnMoreLink: 'categoryPage.optInFlow.step1.modal.learnMoreLink', /* What will I need to do after migrating? */
            bullet3: 'categoryPage.optInFlow.step1.modal.bullet3', /* Get found on Google with a new dedicated URL for each category */
          },
        },
        installation: {
          loader: {
            text1: 'categoryPage.optInFlow.installation.loader.text1', /* Creating a new dynamic page... */
            text2: 'categoryPage.optInFlow.installation.loader.text2', /* Adding advanced SEO capabilities... */
            title: 'categoryPage.optInFlow.installation.loader.title', /* Installing the new Category Page */
          },
        },
        step3: {
          modal: {
            learnMoreLink: 'categoryPage.optInFlow.step3.modal.learnMoreLink', /* Go to the Step-by-Step Guide */
            goToDashboard: {
              button: 'categoryPage.optInFlow.step3.modal.goToDashboard.button', /* Go to Dashboard */
            },
            body: 'categoryPage.optInFlow.step3.modal.body', /* To avoid broken links, SEO issues and problems with site navigation, make sure to complete all of the migration steps listed in our guide. */
            learnMoreURL: 'categoryPage.optInFlow.step3.modal.learnMoreURL', /* https://support.wix.com/en/article/wix-stores-how-to-switch-to-the-new-category-page-experience */
            title: 'categoryPage.optInFlow.step3.modal.title', /* Next, complete your Category Page migration */
          },
        },
      },
    },
    siteMenu: {
      store: {
        defaultPage: {
          name: 'siteMenu.store.defaultPage.name', /* Shop */
        },
      },
    },
    pages: {
      store: {
        categoryPage: {
          hoverTooltip: 'pages.store.categoryPage.hoverTooltip', /* This is a template page for all of your categories. It displays the category's products and a list of your other categories, so customers can easily navigate between them. */
          pageInfo: {
            body2: {
              dashboardLink: 'pages.store.categoryPage.pageInfo.body2.dashboardLink', /* go to your Dashboard */
              text: 'pages.store.categoryPage.pageInfo.body2.text', /* To create more categories, change information or edit the products, {{goToDashboardLink}}. */
            },
            title3: 'pages.store.categoryPage.pageInfo.title3', /* How do I preview the categories? */
            title1: 'pages.store.categoryPage.pageInfo.title1', /* What is the Category Page? */
            body1: {
              text: 'pages.store.categoryPage.pageInfo.body1.text', /* The Category Page is a template page for product categories. Any change you make to this page will affect all of your categories, and each active category gets its own URL.  */
            },
            body4: {
              text: 'pages.store.categoryPage.pageInfo.body4.text', /* Go to the relevant menu, add a link to the category page and choose which category to display. */
            },
            title4: 'pages.store.categoryPage.pageInfo.title4', /* How do I add categories to my menu? */
            title2: 'pages.store.categoryPage.pageInfo.title2', /* How can I change its content? */
            body3: {
              text: 'pages.store.categoryPage.pageInfo.body3.text', /* You can use the preview toolbar at the top of the page to check how different categories look. */
            },
          },
        },
      },
    },
    pagesPanel: {
      stores: {
        settings: {
          deleteStore: {
            button: 'pagesPanel.stores.settings.deleteStore.button', /* Delete Store */
          },
          categoryPage: {
            optIn: {
              button: 'pagesPanel.stores.settings.categoryPage.optIn.button', /* Migrate to Category Page */
            },
          },
          productPage: {
            optIn: {
              button: 'pagesPanel.stores.settings.productPage.optIn.button', /* Switch to New Product Page */
            },
          },
        },
      },
    },
    pages_panel_ecomPlatform_sideCart: 'pages_panel_ecomPlatform_sideCart', /* Side cart */
  };
}

export type ILocaleKeys = ReturnType<typeof LocaleKeys>;
